.custom-shape-divider-top-1629718222 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1629718222 svg {
  position: relative;
  display: block;
  width: calc(128% + 1.3px);
  height: 191px;
}

.custom-shape-divider-top-1629718222 .shape-fill {
  fill: #ffffff;
}

.category-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 4rem;
}

@media screen and (max-width: 800px) {
  .category-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
