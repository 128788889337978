.image-media {
  width: 50%;
  height: auto;
  margin-bottom: 2rem;
}
.main {
  margin-top: 6rem;
  padding: 2rem;
  background: #f4fdff11;
  color: black;
}
p {
  text-align: justify;
  font-size: 16px;
  color: black;
}
h4 {
  color: black;
}
@media screen and (max-width: 800px) {
  .image-media {
    width: 100%;
  }
}
