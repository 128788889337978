#card-parent {
  margin-top: 14vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e8f0f8;
}

#card {
  background: rgb(245, 245, 245, 0.7);
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: auto;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  transition: 0.3s;
  margin: 1rem 2rem;
}

#card:hover {
  transition: 0.3s;
  box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2),
    -8px -8px 16px 0 rgba(0, 0, 0, 0.2);
}

#card img {
  object-fit: cover;
  animation: fadeIn 1s;
  height: 200px;
  width: 30%;
  border-radius: 20px 0 0 20px;
  transition: 0.3s;
}

#content {
  padding: 2vh 1vw 2vh 2vw;
  font-size: 1.3rem;
  font-family: Arial;
  color: #345e82;
}

#content ol {
  padding: 0vh 1vw 0vh 3vw;
  font-size: 1rem;
  color: black;
  font-weight: 700;
  /*   transition:0.3s; */
}
#content ol li {
  display: block;
  transition: 0.3s;
  list-style-type: 123;
  width: auto;
  font-size: 0.8rem;
}

#content ol li:hover {
  display: block;
  transition: 0.3s;
  color: #345e82;
  padding-left: 2vh;
  cursor: pointer;
}
#email {
  color: black;
  font-size: 1rem;
}
#title {
  color: white;
  font-size: 1.1rem;
  padding: 0.3rem 1rem;
  background-color: purple;
  border-radius: 10px;
}
#title-react {
  color: white;
  font-size: 1.1rem;
  padding: 0.3rem 1rem;
  background-color: rgb(105, 197, 233);
  border-radius: 10px;
}
#title-graphic {
  color: white;
  font-size: 1.1rem;
  padding: 0.3rem 1rem;
  background-color: rgb(196, 167, 5);
  border-radius: 10px;
}

@media only screen and (max-width: 750px) {
  #card {
    background: rgb(245, 245, 245, 0.7);
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: auto;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    transition: 0.3s;
    margin: 1rem 2rem;
  }
  #card img {
    object-fit: cover;
    animation: fadeIn 1s;
    height: auto;
    width: 100%;
    border-radius: 20px;
    transition: 0.3s;
  }
}
