.code-media {
  color: gray;
  background: black;
  padding: 5px 17px;
  width: 90%;
  /* font-weight: bold; */
  font-size: 15px;
}

@media screen and (max-width: 800px) {
  .code-media {
    color: Black;
    background: #e3e3e3;
    padding: 5px 17px;
    width: 100%;
  }
}
